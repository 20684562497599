<template>
  <div>
    <data-table
      ref="dataTable"
      :headers="headers"
      server-pagination
      show-expand
      single-expand
      endpoint="/Issue/GetWaitingGeneralIssues"
      :mappingFunction="mappingFunction"
      :search="search"
      @click:row="$emit('click:row', $event)"
    >
      <template v-slot:[`item.orderingCompanyUserId`]="{ item }">
        {{ item.orderingCompanyUser.name }}
        {{ item.orderingCompanyUser.surname }}
      </template>
      <template v-slot:[`item.linkedCompanyUserId`]="{ item }">
        <div v-if="item.linkedCompanyUser">
          {{ item.linkedCompanyUser.name }} {{ item.linkedCompanyUser.surname }}
        </div>
        <div v-else>
          {{ $t('issues.notApplicable') }}
        </div>
      </template>
      <template v-slot:[`item.linkedCompanyDisplayId`]="{ item }">
        <div v-if="item.linkedCompanyDisplayId">
          {{ item.linkedCompanyDisplayId }}
        </div>
        <div v-else>
          {{ $t('issues.notApplicable') }}
        </div>
      </template>
      <template v-slot:[`item.dateOfRegistration`]="{ item }">
        {{ item.dateOfRegistration | dateFormat('DD.MM.YYYY hh:mm') }}
      </template>
      <template v-slot:[`item.issueState`]="{ item }">
        {{ getState(item.issueState) }}
      </template>
      <template v-slot:[`item.currentlyHandledBy`]="{ item }">
        <div>
          <v-select
            v-if="usersToAssign.length > 1 && $permission('AssignIssueToUser')"
            class="mt-n1"
            :items="usersToAssign"
            :placeholder="$t('issues.assign')"
            item-value="id"
            item-text="name"
            dense
            hide-details
            @change="selectUser(item.id, $event)"
          />
          <v-btn
            v-else-if="$permission('IssueTakeover')"
            color="primary"
            @click="selectUser(item.id, $store.getters.userCommonData.id)"
          >
            {{ $t('issues.assignToMe') }}
          </v-btn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-6" :colspan="headers.length">
          <h4>{{ $t('issues.issueState') }}</h4>
          <v-data-table
            hide-default-footer
            :headers="detailsHeaders"
            :items="item.assignmentHistory"
          >
            <template v-slot:[`item.department`]="{ item }">
              {{ item.department | getDescription(departmentTypes) }}
            </template>
            <template v-slot:[`item.dateOfAssignment`]="{ item }">
              {{ item.dateOfAssignment | dateFormat('DD.MM.YYYY hh:mm') }}
            </template>
            <template v-slot:[`item.assignedTo`]="{ item }">
              {{ item.assignedTo.name }} {{ item.assignedTo.surname }}
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-if="$permission('IssueTakeover') && $permission('ViewAllIssues')" v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="
                  $router.push({
                    name: 'DetailsIssueCustomerService',
                    params: { id: item.id }
                  })
                ">
              <v-list-item-title
                >{{ $t('issues.goToDetails') }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
  </div>
</template>

<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'WaitingIssuesTable',
  props: {
    users: {
      type: Array,
      required: true
    },
    usersToAssign: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('issues.orderingUserFullName'),
          value: 'orderingCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'orderingCompanyDisplayId'
        },
        {
          text: this.$t('issues.linkedCompanyUserFullName'),
          value: 'linkedCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'linkedCompanyDisplayId'
        },
        {
          text: this.$t('issues.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('issues.category'),
          value: 'category'
        },
        {
          text: this.$t('issues.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('issues.issueState'),
          value: 'issueState'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'currentlyHandledBy'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('issues.department'),
          value: 'department',
          width: '15%'
        },
        {
          text: this.$t('issues.dateOfAssignment'),
          value: 'dateOfAssignment',
          width: '15%'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'assignedTo',
          width: '20%'
        },
        {
          text: '',
          value: '',
          width: '55%',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      enums: 'enums'
    }),
    departmentTypes () {
      return this.enums.departmentTypes
    }
  },
  methods: {
    async selectUser (issueId, userId) {
      await Service.post('/Issue/AssignIssue', { issueId, UserId: userId })
      await this.$refs.dataTable.getDataDebounce(10)
    },
    getState (value) {
      return getEnumDescription(this.enums.issueState, value)
    },
    getUser (userId) {
      return this.users.find((user) => user.id === userId)
    },
    mappingFunction (result) {
      return result.map((issue) => {
        const user = this.getUser(issue.currentlyHandledBy)
        if (user) {
          issue = {
            ...issue,
            user
          }
        }

        const orderingCompanyUser = this.getUser(issue.orderingCompanyUserId)
        if (orderingCompanyUser) {
          issue = {
            ...issue,
            orderingCompanyUser
          }
        }

        const linkedCompanyUser = this.getUser(issue.linkedCompanyUserId)
        if (linkedCompanyUser) {
          issue = {
            ...issue,
            linkedCompanyUser
          }
        }

        issue.assignmentHistory = issue.assignmentHistory.map((historyItem) => {
          const historyItemUser = this.getUser(historyItem.assignedTo)
          if (historyItemUser) {
            return {
              ...historyItem,
              user: {
                ...historyItemUser
              }
            }
          } else {
            return historyItem
          }
        })

        return issue
      })
    }
  }
}
</script>
