<template>
  <v-card class="pa-5">
    <div class="d-flex align-center mb-6">
      <h2 class="text-subtitle-1 font-weight-bold mr-4">
        {{ $t('issues.issuesList') }}
      </h2>
      <div>
        <SearchInput v-model="searchInputValue" />
      </div>
      <v-btn
        color="primary"
        dark
        class="ml-auto"
        @click="$router.push({ name: 'AddIssueCustomerService' })"
      >
        {{ $t('issues.createIssue') }}
      </v-btn>
    </div>
    <v-tabs v-model="activeTab" @change="changeTab">
      <v-tab>{{ $t('issues.tabs.current') }}</v-tab>
      <v-tab>{{ $t('issues.tabs.waiting') }}</v-tab>
      <v-tab>{{ $t('issues.tabs.archive') }}</v-tab>
      <v-tab>{{ $t('issues.tabs.drafts') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card class="py-5">
          <CurrentIssuesTable
            v-if="activeTab === 0"
            :users="users"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="py-5">
          <WaitingIssuesTable
            v-if="activeTab === 1"
            :users="users"
            :users-to-assign="usersToAssign"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="py-5">
          <ArchivedIssuesTable
            v-if="activeTab === 2"
            :users="users"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="py-5">
          <DraftIssuesTable
            v-if="activeTab === 3"
            :users="users"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '@/services'
import SearchInput from '@/components/search/SearchInput.vue'
import CurrentIssuesTable from './tables/CurrentIssuesTable.vue'
import ArchivedIssuesTable from './tables/ArchivedIssuesTable.vue'
import WaitingIssuesTable from './tables/WaitingIssuesTable.vue'
import DraftIssuesTable from './tables/DraftIssuesTable.vue'

export default {
  name: 'Issues',
  components: {
    CurrentIssuesTable,
    ArchivedIssuesTable,
    WaitingIssuesTable,
    DraftIssuesTable,
    SearchInput
  },
  data () {
    return {
      users: [],
      usersToAssign: [],
      activeTab: null,
      searchInputValue: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'users/users'
    })
  },
  async created () {
    this.activeTab = Number(this.$route.params.activeTab)

    if (this.$permission('AssignIssueToUser') || this.$permission('IssueTakeover')) {
      const { data } = await Service.get('/Issue/GetUsersToAssign')
      this.usersToAssign = data
    }

    this.users = this.getUsers
  },
  methods: {
    async changeTab () {
      await this.$router.push({
        name: 'IssuesCustomerService',
        params: { activeTab: this.activeTab }
      })
    }
  }
}
</script>
